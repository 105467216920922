import * as Game from './Game.js';

function getMove(currentPositions, enPassantMoves, castlingSquares, playerTurn) {
    let chosenMove = null;

    let possibleMoves = [];
    let hasCapturingMoves = false;
    let possibleCapturingMoves = {
        'queen': [], 'rook': [], 'knight': [], 'bishop': [], 'pawn': []
    };
    let allPossibleMoves = [];
    let totalOpponentChessPiecesOnTheBoard = 0;

    Object.keys(enPassantMoves).forEach(from => {
        let enPassantMove = enPassantMoves[from];
        let move = {
            piece: currentPositions[enPassantMove.from],
            from: enPassantMove.from,
            to: enPassantMove.to,
            capturing: true,
            enPassant: enPassantMove.capture
        };

        possibleCapturingMoves['pawn'].push(move);
    });

    Object.keys(currentPositions).forEach(position => {
        let chessPiece = currentPositions[position];

        if (chessPiece && chessPiece['color'] !== playerTurn) {
            totalOpponentChessPiecesOnTheBoard++;
        }

        if (chessPiece && chessPiece['color'] === playerTurn) {
            let movableSquares, capturableSquares;
            [movableSquares, capturableSquares] = Game.getMovableSquares(chessPiece, position, {...currentPositions});

            movableSquares.forEach(movableSquare => {
                let move = {
                    piece: chessPiece,
                    from: position,
                    to: movableSquare
                }

                possibleMoves.push(move);
                allPossibleMoves.push(move);
            });

            capturableSquares.forEach(movableSquare => {
                let move = {
                    piece: chessPiece,
                    from: position,
                    to: movableSquare,
                    capturing: true
                }

                hasCapturingMoves = true;
                possibleCapturingMoves[currentPositions[movableSquare].type].push(move);
                allPossibleMoves.push(move);
            });
        }
    });

    // If there are less than 8 pieces left,
    // Select the move which can give the check to the opposite king
    if (totalOpponentChessPiecesOnTheBoard < 8) {
        let checkMoves = [];
        let checkMateMove = false;

        allPossibleMoves.forEach(possibleMove => {
            let updatedPositions = Game.getPositionsAfterTheMove(possibleMove, {...currentPositions});
            let opponentColor = (playerTurn === 'black') ? 'white' : 'black';

            if (Game.checkIfKingIsInCheck(opponentColor, updatedPositions)) {
                if (! checkIfTheMovingPieceCanBeCaptured(possibleMove, {...currentPositions}, playerTurn)) {
                    checkMoves.push(possibleMove);

                    if (Game.noMovesLeft(opponentColor, {...updatedPositions})) {
                        checkMateMove = possibleMove;
                    }
                }
            }
        });

        if (checkMateMove) {console.log(checkMateMove);
            chosenMove = checkMateMove;
        } else if(checkMoves.length) {
            chosenMove = checkMoves[0];
        }
    }

    if (chosenMove) {
        return chosenMove;
    }

    if (hasCapturingMoves) {
        // Among capturable moves, try to capture the strongest
        let capturingPiece = Object.keys(possibleCapturingMoves).find(chessPiece => {
            if (possibleCapturingMoves[chessPiece].length) {
                return true;
            }
        });
        chosenMove = Object.values(possibleCapturingMoves).flat()[0];
    } else {
        // Prefer castling move if available
        if (castlingSquares.length) {
            return {
                piece: {'type': 'king', color: playerTurn},
                from: (playerTurn === 'black') ? 'e8' : 'e1',
                to: castlingSquares[0],
                castling: true
            };
        }

        // Try to promote the pawn piece
        // If opponet has less chess pieces and a pawn cannot be captured move that pawn
        if (totalOpponentChessPiecesOnTheBoard < 10) {
            chosenMove = getPawnPromotingMove(possibleMoves, {...currentPositions}, playerTurn);
        }

        if (! chosenMove) {
            let randomKey = Math.floor(Math.random() * possibleMoves.length);
            chosenMove = possibleMoves[randomKey];
        }
    }

    return chosenMove;
}

function getPawnPromotingMove(possibleMoves, currentPositions, playerTurn) {
    let blackPromotingRows = {2: [], 3: [], 4: [], 5: []},
        whitePromotingRows = {4: [], 5: [], 6: [], 7: []};

    // Get the pawn pieces which have moved 2 places
    possibleMoves.filter(possibleMove => {
        if (possibleMove.piece.type == 'pawn') {
            if (playerTurn == 'white' && parseInt(possibleMove.from[1]) >= 4) {
                whitePromotingRows[possibleMove.from[1]].push(possibleMove);
            }

            if (playerTurn == 'black' && parseInt(possibleMove.from[1]) < 6) {
                blackPromotingRows[possibleMove.from[1]].push(possibleMove);
            }
        }
    });

    let promotingMoves = {};
    promotingMoves['black'] = Object.values(blackPromotingRows).flat();
    promotingMoves['white'] = Object.values(whitePromotingRows).flat().reverse();

    return promotingMoves[playerTurn].find(promotingMove => {
        return ! checkIfTheMovingPieceCanBeCaptured(promotingMove, {...currentPositions}, playerTurn);
    });
}

function checkIfTheMovingPieceCanBeCaptured(move, currentPositions, playerTurn) {
    let updatedPositions = Game.getPositionsAfterTheMove(move, {...currentPositions});
    let opponentColor = (playerTurn === 'black') ? 'white' : 'black';
    let canBeCaptured = false;

    Object.keys(updatedPositions).forEach(position => {
        let chessPiece = updatedPositions[position];

        if (chessPiece && chessPiece['color'] === opponentColor) {
            let movableSquares, capturableSquares;
            [movableSquares, capturableSquares] = Game.getMovableSquares(chessPiece, position, {...updatedPositions});

            capturableSquares.forEach(movableSquare => {
                if (movableSquare == move.to) {
                    canBeCaptured = true;
                }
            });
        }
    });

    return canBeCaptured;
}

export {getMove};