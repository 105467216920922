import { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import ChessSquare from './ChessSquare.js';
import GameWon from './GameWon.js';
import GameDrawn from './GameDrawn.js';
import GameMoves from './GameMoves.js';
import CapturedPieces from './CapturedPieces.js';
import DemoInfo from './DemoInfo.js';
import { checkForPawnPromotion, promptForPromotedPieceSelection } from './Game/PawnPromotion.js';
import * as Game from './Game/Game.js';
import { useEnPassant } from './Game/useEnPassant.js';
import { useCastling } from './Game/useCastling.js';
import { useDraw } from './Game/useDraw.js';
import { useRecordMoves } from './Game/useRecordMoves.js';
import { ThemeContext } from './ThemeContext.js';
import * as GameAlgorithm from './Game/GameAlgorithm.js';

function GameBoard(props) {
    const [currentPositions, setCurrentPositions] = useState(require('./Game/initial-positions.json'));
    const [activeSquare, setActiveSquare] = useState('');
    const [movableSquares, setMovableSquares] = useState([]);
    const [capturableSquares, setCapturableSquares] = useState([]);
    const [inCheck, setInCheck] = useState({white: false, black: false});
    const [checkMate, setCheckMate] = useState(false);
    const [enPassantMoves, checkForEnPassant] = useEnPassant();
    const [castlingSquares, updateCastlingSquares, updateCastlingEligibility] = useCastling();
    const [capturedPieces, setCapturedPieces] = useState([]);
    const [draw, checkForDraw] = useDraw();
    const [moveNotations, recordMove] = useRecordMoves();
    const [playerTurn, setPlayerTurn] = useState('white');
    const theme = useContext(ThemeContext);

    useEffect(() => {
        if (playerTurn == 'black') {
            // console.log('Display hour glass gif...?');
            if (checkMate !== false) return;
            if (draw !== false) return;

            setTimeout(() => {
                // Get the moveObject
                const move = GameAlgorithm.getMove(
                    {...currentPositions},
                    enPassantMoves,
                    castlingSquares,
                    playerTurn
                );

            // Call makeMove
                makeMove(move);
            }, 2000);
        }
    }, [playerTurn]);

    function getMoves(piece, position) {
        if ((piece.color !== playerTurn) || checkMate || draw) {
            return;
        }

        setActiveSquare(position);

        let newMovableSquares, newCapturableSquares;
        [newMovableSquares, newCapturableSquares] = Game.getMovableSquares(piece, position, {...currentPositions});

        setMovableSquares(newMovableSquares);
        setCapturableSquares(newCapturableSquares);
        updateCastlingSquares(piece, position, {...currentPositions});
    }

    function makeMove(move) {
        let updatedPositions = Game.getPositionsAfterTheMove(move, {...currentPositions});
        let opponentColor = move.piece.color == 'white' ? 'black' : 'white';

        checkForPawnPromotion(move)
            .then((pawnPromoted) => {
                if (! pawnPromoted) {
                    return false;
                }

                if (playerTurn == 'black') {
                    return {type: 'queen', color: 'black'};
                }

                // If the pawn is promoted, then prompt for the promoted piece selection
                return new Promise((resolve, reject) => {
                    promptForPromotedPieceSelection({
                        resolve: resolve,
                        promotedPawnColor: move.piece.color,
                        theme: theme
                    });
                });
            }).then((promotedPiece) => {
                // Add the promoted piece to the board
                if (promotedPiece) {
                    updatedPositions[move.to] = promotedPiece;
                    move.promotedPiece = promotedPiece;
                }

                return true;
            }).then(() => {
                // Check if king is in check
                let updatedInCheck = {white: false, black: false};
                updatedInCheck[opponentColor] = Game.checkIfKingIsInCheck(opponentColor, {...updatedPositions});

                setInCheck(updatedInCheck);
                return updatedInCheck[opponentColor];
            }).then((inCheck) => {
                if (inCheck) {
                    move.check = true;
                }

                // Check for checkmate
                if (inCheck && Game.noMovesLeft(opponentColor, {...updatedPositions})) {
                    setCheckMate(move.piece.color);
                    move.checkMate = true;
                    return;
                }

                checkForDraw(opponentColor, {...updatedPositions});
                return;
            }).then(() => {
                recordMove(move, {...currentPositions});

                setCapturedPieces(updateCapturedPieces(move));
                setCurrentPositions(updatedPositions);
                updateCastlingEligibility(move);
                checkForEnPassant(move, {...updatedPositions});
            }).finally(() => {
                setActiveSquare('');
                setMovableSquares([]);
                setCapturableSquares([]);
                setPlayerTurn(opponentColor);

                // System has to make the castling move
                if (opponentColor == 'black') {
                    if (updatedPositions['e8'] && updatedPositions['e8']['type'] == 'king') {
                        updateCastlingSquares(updatedPositions['e8'], 'e8', {...updatedPositions});
                    }
                }
            });

        if (! props.gameStarted) {
            props.setGameStarted(true);
        }
    }

    function updateCapturedPieces(move) {
        let currentCapturedPieces = capturedPieces;
        if (move.capturing) {
            if (move.enPassant) {
                currentCapturedPieces.push(currentPositions[move.enPassant]);
            } else {
                currentCapturedPieces.push(currentPositions[move.to]);
            }
        }

        return currentCapturedPieces;
    }

    const chessSquares = [];
    let squareColor = 'square-light';
    for(let row = 8; row > 0; row--) {
        let column = 'a';
        for(let i = 8; i > 0; i--) {
            let squareId = column+row;
            chessSquares.push(
                <ChessSquare
                    key={squareId}
                    currentSquare={squareId}
                    color={squareColor}
                    piece={currentPositions[squareId]}
                    activeSquare={ activeSquare }
                    activePiece={ currentPositions[activeSquare] }
                    movablePosition={movableSquares.includes(squareId)}
                    capturablePosition={capturableSquares.includes(squareId)}
                    castlingPosition={castlingSquares.includes(squareId)}
                    inCheck={inCheck}
                    enPassantMove={ Object.keys(enPassantMoves).includes(activeSquare) ? enPassantMoves[activeSquare] : false }
                    getMoves={getMoves}
                    makeMove={makeMove}
                    algorithmMove={ playerTurn === 'black' }
                />
            );

            column = String.fromCharCode(column.charCodeAt() + 1);
            squareColor = (squareColor == 'square-light') ? 'square-dark' : 'square-light';
        }

        squareColor = (squareColor == 'square-light') ? 'square-dark' : 'square-light';
    }

    return (
        <>
            {
                checkMate &&
                createPortal(
                    <GameWon color={checkMate} />,
                    document.body
                )
            }
            {
                draw !== false &&
                createPortal(
                    <GameDrawn draw={draw} />,
                    document.body
                )
            }
            <div className="row app-row">
                <div className="col-md-9">
                    <div id="chess-board" className={theme}>
                        { chessSquares }
                    </div>
                </div>

                <div className="col-md-3 px-0 game-info-sidebar">
                    <DemoInfo />

                    <CapturedPieces capturedPieces={capturedPieces} />

                    <GameMoves moveNotations={moveNotations} setHeight={capturedPieces.length} />
                </div>
            </div>
        </>
    );
}

export default GameBoard;