import {useState, useEffect, useContext} from 'react';
import {Modal} from 'bootstrap';
import { ThemeContext } from './ThemeContext.js';

function GameWon(props) {
    const [modalInstance, setModalInstance] = useState(false);
    const theme = useContext(ThemeContext);

    useEffect(() => {
        let gameWonModal = new Modal(document.getElementById('game-won-modal'), {});
        gameWonModal.show();
        setModalInstance(gameWonModal);
    }, []);

    function closeModal() {
        modalInstance.hide();

        let modalBackDrops = document.getElementsByClassName('modal-backdrop');
        while(modalBackDrops.length) {
            modalBackDrops[0].remove();
        }
    }

    return (
        <div className={"modal game-won-modal modal-" + theme} tabIndex="-1" id="game-won-modal" data-bs-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-header justify-content-center">
                        {
                            props.color == 'white' &&
                            <h5 className="modal-title fw-bold">Congratulations! You won the Game!</h5>
                        }
                        {
                            props.color == 'black' &&
                            <h5 className="modal-title fw-bold">Oops! System won the Game!</h5>
                        }
                    </div>
                    <div className="modal-body">
                        <h4>Game won by { props.color.charAt(0).toUpperCase() + props.color.slice(1) }</h4>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className={"btn btn-primary btn-" + theme} onClick={closeModal}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameWon;