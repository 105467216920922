import * as ChessMoves from './ChessMoves.js';

function ChessPiece() {
}
ChessPiece.prototype.color = '';
ChessPiece.prototype.boardPositions = {};
ChessPiece.prototype.moveDirections = [];
ChessPiece.prototype.getPossibleMoves = function(position) {
    let possibleMoves = [];
    let capturableMoves = [];
    let nextSquare = false;

    this.moveDirections.forEach(moveDirection => {
        nextSquare = ChessMoves.getNextSquare(position, moveDirection);

        while(nextSquare !== false && this.canMoveTo(nextSquare)) {
            possibleMoves.push(nextSquare);
            if (this.canCapture(nextSquare)) {
                capturableMoves.push(nextSquare);
                nextSquare = false;
            } else {
                nextSquare = ChessMoves.getNextSquare(nextSquare, moveDirection);
            }

            if (this instanceof King) {
                nextSquare = false;
            }
        }
    });

    return [possibleMoves, capturableMoves];
}
ChessPiece.prototype.canMoveTo = function(position) {
    let pieceInPosition = this.boardPositions[position];
    if (pieceInPosition) {
        // Pawns cannot capture in the direction of movement and so are blocked
        if (this instanceof Pawn) {
            return false;
        }

        if (pieceInPosition.color == this.color) {
            return false;
        }
    }

    return true;
}
ChessPiece.prototype.canCapture = function(position) {
    let pieceInPosition = this.boardPositions[position];
    if (pieceInPosition && pieceInPosition.color != this.color) {
        return true;
    }

    return false;
}

/******************** Pawn Movment ********************/
function Pawn(color, boardPositions) {
    this.color = color;
    this.boardPositions = boardPositions;
}
Object.setPrototypeOf(Pawn.prototype, ChessPiece.prototype);
Pawn.prototype.getPossibleMoves = function(position) {
    // Pawn moves one step in the forward direction
    let possibleMoves = [];
    let capturableMoves = [];
    const moveDirection = this.color == 'white' ? 'forward' : 'backward';
    let nextSquare = ChessMoves.getNextSquare(position, moveDirection);

    if (this.canMoveTo(nextSquare)) {
        possibleMoves.push(nextSquare);

        // Pawn can move two steps from its initial position
        if ((this.color == 'white' && position[1] == '2') || (this.color == 'black' && position[1] == '7')) {
            nextSquare = ChessMoves.getNextSquare(nextSquare, moveDirection);
            if (this.canMoveTo(nextSquare)) {
                possibleMoves.push(nextSquare);
            }
        }
    }


    // Pawn can capture in the two diagonal directions
    nextSquare = ChessMoves.getNextSquare(position, moveDirection + '-right');
    if (this.canCapture(nextSquare)) {
        possibleMoves.push(nextSquare);
        capturableMoves.push(nextSquare);
    }
    nextSquare = ChessMoves.getNextSquare(position, moveDirection + '-left');
    if (this.canCapture(nextSquare)) {
        possibleMoves.push(nextSquare);
        capturableMoves.push(nextSquare);
    }

    return [possibleMoves, capturableMoves];
}
/********************************************************/

/******************** Knight Movment ********************/
function Knight(color, boardPositions) {
    this.color = color;
    this.boardPositions = boardPositions;
}
Object.setPrototypeOf(Knight.prototype, ChessPiece.prototype);
Knight.prototype.getPossibleMoves = function(position) {
    let possibleMoves = ChessMoves.getKnightMovableSquares(position);
    possibleMoves = possibleMoves.filter(possibleMove => this.canMoveTo(possibleMove));
    let capturableMoves = possibleMoves.filter(possibleMove => this.canCapture(possibleMove));

    return [possibleMoves, capturableMoves];
}
/********************************************************/

/******************** Bishop Movment ********************/
function Bishop(color, boardPositions) {
    this.color = color;
    this.boardPositions = boardPositions;
    this.moveDirections = ['forward-right', 'forward-left', 'backward-right', 'backward-left'];
}
Object.setPrototypeOf(Bishop.prototype, ChessPiece.prototype);
/********************************************************/

/********************* Rook Movment *********************/
function Rook(color, boardPositions) {
    this.color = color;
    this.boardPositions = boardPositions;
    this.moveDirections = ['forward', 'backward', 'horizontal-right', 'horizontal-left'];
}
Object.setPrototypeOf(Rook.prototype, ChessPiece.prototype);
/********************************************************/

/********************* Queen Movment *********************/
function Queen(color, boardPositions) {
    this.color = color;
    this.boardPositions = boardPositions;
    this.moveDirections = [
        'forward',
        'backward',
        'horizontal-right',
        'horizontal-left',
        'forward-right',
        'forward-left',
        'backward-right',
        'backward-left'
    ];
}
Object.setPrototypeOf(Queen.prototype, ChessPiece.prototype);
/********************************************************/

/********************* King Movment *********************/
function King(color, boardPositions) {
    this.color = color;
    this.boardPositions = boardPositions;
    this.moveDirections = [
        'forward',
        'backward',
        'horizontal-right',
        'horizontal-left',
        'forward-right',
        'forward-left',
        'backward-right',
        'backward-left'
    ];
}
Object.setPrototypeOf(King.prototype, ChessPiece.prototype);
King.prototype.isInCheck = function() {
    let checkPositions = [];
    // Get the King's position
    let kingsPosition = Object.keys(this.boardPositions).find(position => {
        return this.boardPositions[position]['type'] == 'king'
            && this.boardPositions[position]['color'] == this.color;
    });

    // Check the trouble from opponent Knight pieces
    let opponentColor = this.color == 'white' ? 'black' : 'white';
    let knightsPositions = Object.keys(this.boardPositions).filter(position => {
        return this.boardPositions[position]['type'] == 'knight'
            && this.boardPositions[position]['color'] == opponentColor;
    });
    knightsPositions.forEach(knightPosition => {
        let knight = new Knight(opponentColor, this.boardPositions);

        let [possibleMoves, capturableMoves] = knight.getPossibleMoves(knightPosition);
        if (capturableMoves.includes(kingsPosition)) {
            checkPositions.push(knightPosition);
        }
    });

    // Check the trouble from other pieces
    // Get the capturable positions for the Queen as if it is placed in King's position
    let [possibleMoves, capturableMoves] = (new Queen(this.color, this.boardPositions)).getPossibleMoves(kingsPosition);
    capturableMoves.forEach(capturablePosition => {
        // Find the chess pieces in those capturable positions
        let chessPiece = getChessPiece(this.boardPositions[capturablePosition], this.boardPositions);

        // Check if these pieces can capture the king
        let [possibleMoves, capturableMoves] = chessPiece.getPossibleMoves(capturablePosition);
        if (capturableMoves.includes(kingsPosition)) {
            checkPositions.push(capturablePosition);
        }
    });

    return checkPositions.length ? true : false;
}
/********************************************************/

function getChessPiece(piece, boardPositions) {
    switch(piece.type) {
        case 'pawn':
            return new Pawn(piece.color, boardPositions);
        case 'knight':
            return new Knight(piece.color, boardPositions);
        case 'bishop':
            return new Bishop(piece.color, boardPositions);
        case 'rook':
            return new Rook(piece.color, boardPositions);
        case 'queen':
            return new Queen(piece.color, boardPositions);
        case 'king':
            return new King(piece.color, boardPositions);
    }
}

function getMovableSquares(piece, position, boardPositions) {
    let chessPieceObject = getChessPiece(piece, boardPositions);
    let [newMovableSquares, newCapturableSquares] = chessPieceObject.getPossibleMoves(position);

    // Allow only moves which doesn't leave the king in check
    newMovableSquares = newMovableSquares.filter(movableSquare => {
        return ! willThisMoveLeaveTheKingInCheck({
                    piece: piece,
                    from: position,
                    to: movableSquare
                }, {...boardPositions});
    })
    newCapturableSquares = newCapturableSquares.filter(movableSquare => {
        return ! willThisMoveLeaveTheKingInCheck({
                    piece: piece,
                    from: position,
                    to: movableSquare
                }, {...boardPositions});
    })

    return [newMovableSquares, newCapturableSquares];
}

function checkIfKingIsInCheck(color, boardPositions) {
    let king = new King(color, boardPositions);

    return king.isInCheck();
}

function willThisMoveLeaveTheKingInCheck(move, currentPositions) {
    let updatedPositions = getPositionsAfterTheMove(move, currentPositions);

    return checkIfKingIsInCheck(move.piece.color, {...updatedPositions});
}

function noMovesLeft(color, boardPositions) {
    let hasMoves = Object.keys(boardPositions).some((position) => {
        let chessPiece = boardPositions[position];
        if (chessPiece && chessPiece.color == color) {
            let [newMovableSquares, newCapturableSquares] = getMovableSquares(chessPiece, position, {...boardPositions});
            if (newMovableSquares.length || newCapturableSquares.length) {
                return true;
            }
        }

        return false;
    });

    return ! hasMoves;
}

function getPositionsAfterTheMove(move, currentPositions) {
    let updatedPositions = {...currentPositions};
    if (move.castling) {
        let castlingPositions = {
            'a1': {king: 'c1', rook: 'd1'},
            'h1': {king: 'g1', rook: 'f1'},
            'a8': {king: 'c8', rook: 'd8'},
            'h8': {king: 'g8', rook: 'f8'}
        };
        let kingsPosition = move.from, rooksPosition = move.to;
        if (move.piece.type == 'rook') {
            kingsPosition = move.to;
            rooksPosition = move.from;
        }

        let newPositions = castlingPositions[rooksPosition];
        updatedPositions[newPositions.king] = updatedPositions[kingsPosition];
        updatedPositions[newPositions.rook] = updatedPositions[rooksPosition];

        delete updatedPositions[kingsPosition];
        delete updatedPositions[rooksPosition];
    } else {
        updatedPositions[move.to] = updatedPositions[move.from];
        delete updatedPositions[move.from];

        if (move.enPassant) {
            delete updatedPositions[move.enPassant];
        }
    }

    return updatedPositions;
}

export {getMovableSquares, getPositionsAfterTheMove, checkIfKingIsInCheck, willThisMoveLeaveTheKingInCheck, noMovesLeft};