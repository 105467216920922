import { useState } from 'react';
import * as Game from './Game.js';

function useDraw() {
    const [draw, setDraw] = useState(false);

    const getSquareColor = function (position) {
        if (['a', 'c', 'e', 'g'].includes(position[0])) {
            if (parseInt(position[1]) % 2 == 0) {
                return 'white';
            } else {
                return 'black';
            }
        } else {
            if (parseInt(position[1]) % 2 == 0) {
                return 'black';
            } else {
                return 'white';
            }
        }
    }

    const deadPosition = function (boardPositions) {
        let chessPieces = {white: [], black: []};
        let bishopPositions = {white: [], black: []};

        Object.keys(boardPositions).forEach(position => {
            let chessPiece = boardPositions[position];
            if (chessPiece) {
                chessPieces[chessPiece.color].push(chessPiece);
            }

            if (chessPiece.type == 'bishop') {
                if (chessPiece.color == 'white') {
                    bishopPositions.white.push(position);
                } else {
                    bishopPositions.black.push(position);
                }
            }
        });

        if (chessPieces.white.length + chessPieces.black.length > 4) {
            return false;
        }

        if (chessPieces.white.length > 2 || chessPieces.black.length > 2) {
            return false;
        }

        // King vs. King
        if (chessPieces.white.length == 1 && chessPieces.black.length == 1) {
            return true;
        }

        // King and Bishop vs. King
        // King and Kinght vs. King
        if (chessPieces.white.length == 1) {
            // There are only 2 black pieces,
            // so check if the other black piece is bishop or knight piece
            let knightOrBishopPieces = chessPieces.black.some(chessPiece => {
                if (['bishop', 'knight'].includes(chessPiece.type)) {
                    return true;
                }
            });

            if (knightOrBishopPieces) {
                return true;
            }
        }

        if (chessPieces.black.length == 1) {
            // There are only 2 white pieces,
            // so check if the other white piece is bishop or knight piece
            let knightOrBishopPieces = chessPieces.white.some(chessPiece => {
                if (['bishop', 'knight'].includes(chessPiece.type)) {
                    return true;
                }
            });

            if (knightOrBishopPieces) {
                return true;
            }
        }

        // King and Bishop vs. King and Bishop of the same color
        if (bishopPositions.white.length == 1 && bishopPositions.black.length == 1) {
            if (getSquareColor(bishopPositions.white[0]) == getSquareColor(bishopPositions.black[0])) {
                return true;
            }
        }

        return false;
    }

    const checkForDraw = function (opponentColor, boardPositions) {
        // DeadPosition
        if (deadPosition(boardPositions)) {
            setDraw('Dead Position');
            return;
        }

        // Check for Stalemate
        if (Game.noMovesLeft(opponentColor, {...boardPositions})) {
            setDraw('Stalemate');
            return;
        }
    }

    return [draw, checkForDraw];
}

export { useDraw };