import { useState } from 'react';
import * as Game from './Game.js';
import * as ChessMoves from './ChessMoves.js';

function useEnPassant() {
    const [enPassantMoves, setEnPassantMoves] = useState({});

    const checkForEnPassant = function (move, boardPositions) {
        // EnPassant Rules: Allowed only for pawn pieces, The pawn piece must have moved 2 squares
        if (move.piece.type !== 'pawn') {
            setEnPassantMoves({});
            return;
        }
        if (Math.abs(move.to[1] - move.from[1]) !== 2) {
            setEnPassantMoves({});
            return;
        }

        let newEnPassantMoves = {};
        // Get the EnPassant square
        let enPassantSquare = move.piece.color == 'white' ? (move.to[0] + 3) : (move.to[0] + 6);

        // Get the adjacent squares and check if they have the opponent pawn pieces
        let adjacentSquares = [
            ChessMoves.getNextSquare(move.to, 'horizontal-left'),
            ChessMoves.getNextSquare(move.to, 'horizontal-right')
        ];
        adjacentSquares.forEach(adjacentSquare => {
            let chessPiece = boardPositions[adjacentSquare];

            if (chessPiece && chessPiece.type == 'pawn' && chessPiece.color !== move.piece.color) {
                // EnPassant is enabled for this pawn piece
                newEnPassantMoves[adjacentSquare] = {
                    from: adjacentSquare,
                    to: enPassantSquare,
                    capture: move.to
                }
            }
        });

        // Make sure the EnPassant move will not leave the King in check
        Object.keys(newEnPassantMoves).forEach(fromPosition => {
            let enPassantMove = newEnPassantMoves[fromPosition];
            let kingInCheck = Game.willThisMoveLeaveTheKingInCheck({
                piece: boardPositions[fromPosition],
                from: enPassantMove.from,
                to: enPassantMove.to,
                enPassant: enPassantMove.capture
            }, {...boardPositions});

            if (kingInCheck) {
                delete newEnPassantMoves[fromPosition];
            }
        });

        setEnPassantMoves(newEnPassantMoves);
    }

    return [enPassantMoves, checkForEnPassant];
}

export { useEnPassant };