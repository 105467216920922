import { useContext } from 'react';
import { ThemeContext } from './ThemeContext.js';

function CapturedPieces (props) {
    const theme = useContext(ThemeContext);
    const chessPieceImages = {
        'black-rook': 'images/rook-black.png',
        'black-knight': 'images/knight-black.png',
        'black-bishop': 'images/bishop-black.png',
        'black-queen': 'images/queen-black.png',
        'black-king': 'images/king-black.png',
        'black-pawn': 'images/pawn-black.png',
        'white-rook': 'images/rook-white.png',
        'white-knight': 'images/knight-white.png',
        'white-bishop': 'images/bishop-white.png',
        'white-queen': 'images/queen-white.png',
        'white-king': 'images/king-white.png',
        'white-pawn': 'images/pawn-white.png',
    };

    const whiteCapturedPieces = [];
    const blackCapturedPieces = [];
    props.capturedPieces.forEach((chessPiece, index) => {
        if (chessPiece.color == 'white') {
            whiteCapturedPieces.push(
                <img key={index} src={ chessPieceImages[chessPiece.color + '-' + chessPiece.type] } />
            );
        }

        if (chessPiece.color == 'black') {
            blackCapturedPieces.push(
                <img key={index} src={ chessPieceImages[chessPiece.color + '-' + chessPiece.type] } />
            );
        }
    })

    return (
        <>
            <div className={"card mb-2 captured-pieces card-" + theme}>
                <div className="card-header">
                    <h3 className="text-center m-0">Captured Pieces</h3>
                </div>

                <div className="card-body p-1">
                    {
                        props.capturedPieces.length == 0 &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <h5 className="text-muted my-2">No pieces captured!</h5>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-12 text-center">
                            { whiteCapturedPieces }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            { blackCapturedPieces }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CapturedPieces;