import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import GameBoard from './GameBoard.js';
import DemoInfo from './DemoInfo.js';
import ConfirmNewGame from './ConfirmNewGame.js';
import { ThemeContext } from './ThemeContext.js';

function App() {
    const [gameStarted, setGameStarted] = useState(false);
    const [confirmNewGame, setConfirmNewGame] = useState(false);
    const [gameKey, setGameKey] = useState(1);
    const [theme, setTheme] = useState('orange');
    const [appTheme, setAppTheme] = useState('dark');

    useEffect(() => {
        document.title = "Chess Game - Built with React JS";

        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            const chessBoard = document.getElementById('chess-board');
            chessBoard.style.height = chessBoard.getBoundingClientRect().width + 'px';
        }
    }, [gameKey]);

    function startNewGame(startNewGame) {
        if (startNewGame) {
            setGameKey(gameKey + 1);
            setGameStarted(false);
        }

        setConfirmNewGame(false);
    }

    return (
        <ThemeContext.Provider value={theme}>
            <>
                {
                    confirmNewGame &&
                    createPortal(
                        <ConfirmNewGame startNewGame={startNewGame} />,
                        document.body
                    )
                }

                <div
                    className={"app-theme-icon " + appTheme}
                    onClick={() => appTheme == 'dark' ? setAppTheme('light') : setAppTheme('dark')}
                    >
                    {
                        appTheme == 'light' &&
                        <img src="/images/fa-moon.svg" width="24px" />
                    }

                    {
                        appTheme == 'dark' &&
                        <img src="/images/fa-light.svg" width="24px" />
                    }
                </div>

                <div className={"App "+ appTheme +"-layout"}>
                    <div className="bg-circles">
                        <div className="bg-circle outer-circle">
                            <div className="bg-circle inner-circle">
                            </div>
                        </div>
                    </div>

                    <div className="bg-squares">
                        <div className="bg-square bg-square-1"></div>
                        <div className="bg-square bg-square-2"></div>
                        <div className="bg-square bg-square-3"></div>
                    </div>

                    <div className="desktop-logo">
                        <a href="https://codingwithchess.com">
                            <img
                                className="mb-2"
                                src={"https://codingwithchess.com/images/" + ((appTheme == 'dark') ? 'logo.svg' : 'logo-blue.svg')}
                                alt="CodingWithChess Logo"
                                height="50px"
                                />
                        </a>
                    </div>

                    <div className="container-fluid app-container py-3 px-4 px-lg-2 pe-lg-5">
                        <div className="row mb-3 mobile-top-section">
                            <div className="col-12 text-center">
                                <a href="https://codingwithchess.com">
                                    <img
                                        className="mb-2"
                                        src={"https://codingwithchess.com/images/" + ((appTheme == 'dark') ? 'logo.svg' : 'logo-blue.svg')}
                                        alt="CodingWithChess Logo"
                                        height="65px"
                                        />
                                </a>

                                <DemoInfo />
                            </div>
                        </div>

                        <div className="row app-row mb-1">
                            <div className="col-md-9">
                                <div className="row top-bar">
                                    <div className="col-6 ps-0">
                                        <button
                                            className={"btn btn-primary btn-" + theme}
                                            disabled={ gameStarted ? false : true }
                                            onClick={() => setConfirmNewGame(true)}
                                            >
                                            New Game
                                        </button>
                                    </div>
                                    <div className="col-6 mb-2 mb-md-0 px-0 d-inline-grid align-items-end">
                                        <div className="d-flex justify-content-end">
                                            <div className={"theme-control orange " + (theme == 'orange' ? 'active' : '')} onClick={() => setTheme('orange')}>
                                                <img src="/images/fa-check.svg" alt="Check Symbol" width="18px" />
                                            </div>
                                            <div className={"theme-control blue " + (theme == 'blue' ? 'active' : '')} onClick={() => setTheme('blue')}>
                                                <img src="/images/fa-check.svg" alt="Check Symbol" width="18px" />
                                            </div>
                                            <div className={"theme-control green " + (theme == 'green' ? 'active' : '')} onClick={() => setTheme('green')}>
                                                <img src="/images/fa-check.svg" alt="Check Symbol" width="18px" />
                                            </div>
                                            <div className={"theme-control wooden " + (theme == 'wooden' ? 'active' : '')} onClick={() => setTheme('wooden')}>
                                                <img src="/images/fa-check.svg" alt="Check Symbol" width="18px" />
                                            </div>
                                            <div className={"theme-control black " + (theme == 'black' ? 'active' : '')} onClick={() => setTheme('black')}>
                                                <img src="/images/fa-check.svg" alt="Check Symbol" width="18px" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                            </div>
                        </div>

                        <GameBoard key={gameKey} gameStarted={gameStarted} setGameStarted={setGameStarted} />
                    </div>
                </div>
            </>
        </ThemeContext.Provider>
    );
}

export default App;
