function ChessSquare(props) {
    const chessPieceImages = {
        'black': {
            'rook': 'images/rook-black.png',
            'knight': 'images/knight-black.png',
            'bishop': 'images/bishop-black.png',
            'queen': 'images/queen-black.png',
            'king': 'images/king-black.png',
            'pawn': 'images/pawn-black.png',
        },
        'white': {
            'rook': 'images/rook-white.png',
            'knight': 'images/knight-white.png',
            'bishop': 'images/bishop-white.png',
            'queen': 'images/queen-white.png',
            'king': 'images/king-white.png',
            'pawn': 'images/pawn-white.png',
        }
    };

    let className = 'chess-square ' + props.color;
    className += props.piece ? ' has-piece' : '';

    if (! props.algorithmMove) {
        className += (props.activeSquare == props.currentSquare) ? ' active' : '';
        className += props.movablePosition ? ' movable-position' : '';
        className += props.capturablePosition ? ' capturable-position' : '';
        className += props.castlingPosition ? ' castling-position' : '';
    }

    if (props.piece && props.piece.type == 'king' && props.inCheck[props.piece.color]) {
        className += ' in-check';
    }

    let enPassantSquare = false;
    if (props.enPassantMove && props.enPassantMove.to == props.currentSquare) {
        className += ' enpassant';
        enPassantSquare = true;
    }

    function handleClick(props, enPassantSquare) {
        if (props.algorithmMove) return;

        if (props.movablePosition || props.castlingPosition || enPassantSquare) {
            let move = {
                piece: props.activePiece,
                from: props.activeSquare,
                to: props.currentSquare
            };
            if (enPassantSquare) {
                move.enPassant = props.enPassantMove.capture;
                move.capturing = true;
            }
            if (props.castlingPosition) {
                move.castling = true;
            }
            if (props.capturablePosition) {
                move.capturing = true;
            }

            props.makeMove(move);
            return;
        }

        if (! props.piece) {
            return;
        }
        props.getMoves(props.piece, props.currentSquare);
    }

    return (
        <div
            className={className}
            data-square-id={props.currentSquare}
            onClick={() => handleClick(props, enPassantSquare)}
        >
            {
                props.piece &&
                <img
                    src={chessPieceImages[props.piece.color][props.piece.type]}
                    alt={props.piece.color + '-' + props.piece.type}
                />
            }
        </div>
    );
}

export default ChessSquare;