import { useState } from 'react';
import * as Game from './Game.js';

function useCastling() {
    const [castlingSquares, setCastlingSquares] = useState([]);
    const [eligibleForCastling, setEligibleForCastling] = useState({white: true, black: true});
    const [rookMovedStatus, setRookMovedStatus] = useState({'a1': false, 'h1': false, 'a8': false, 'h8': false});

    const disableCastlingFor = function (color) {
        let updatedEligibleForCastling = {...eligibleForCastling};
        updatedEligibleForCastling[color] = false;
        setEligibleForCastling(updatedEligibleForCastling);
    }

    const updateCastlingEligibility = function (move) {
        setCastlingSquares([]);

        // If the King has moved then the castling is disabled
        if (move.piece.type === 'king') {
            disableCastlingFor(move.piece.color);
            return;
        }

        // If the Rook has moved then the castling is disabled for that Rook
        if (move.piece.type !== 'rook' && ! ['a1', 'h1', 'a8', 'h8'].includes(move.from)) {
            return;
        }

        let updatedRookMovedStatus = {...rookMovedStatus};
        updatedRookMovedStatus[move.from] = true;

        if (updatedRookMovedStatus['a1'] && updatedRookMovedStatus['h1']) {
            disableCastlingFor('white');
        }

        if (updatedRookMovedStatus['a8'] && updatedRookMovedStatus['h8']) {
            disableCastlingFor('black');
        }

        setRookMovedStatus(updatedRookMovedStatus);
    }

    const canMakeCastlingMoves = function (piece, position) {
        if (! ['king', 'rook'].includes(piece.type)) {
            return false;
        }

        if (! eligibleForCastling[piece.color]) {
            return false;
        }

        return true;
    }

    const updateCastlingSquares = function (piece, position, boardPositions) {
        // Check if the Castling is allowed
        if (! canMakeCastlingMoves(piece, position)) {
            setCastlingSquares([]);
            return;
        }

        const castlingPositions = {
            'a1': ['e1'],
            'h1': ['e1'],
            'e1': ['a1', 'h1'],
            'a8': ['e8'],
            'h8': ['e8'],
            'e8': ['a8', 'h8']
        };
        const rookPositions = {
            'a1': { intermediate_squares: ['b1', 'c1', 'd1'], passing_square: 'd1' },
            'h1': { intermediate_squares: ['f1', 'g1'], passing_square: 'f1' },
            'a8': { intermediate_squares: ['b8', 'c8', 'd8'], passing_square: 'd8' },
            'h8': { intermediate_squares: ['f8', 'g8'], passing_square: 'f8' }
        }

        // Get the possible Castling Moves
        let possibleCastlingSquares = castlingPositions[position] ?? [];
        possibleCastlingSquares = possibleCastlingSquares.filter(possibleCastlingSquare => {
            let rooksPosition = (piece.type === 'rook') ? position : possibleCastlingSquare;
            let kingsPosition = (piece.type === 'king') ? position : possibleCastlingSquare;

            // Check if the Rook has moved before
            if (rookMovedStatus[rooksPosition]) {
                return false;
            }

            // Check if there is a piece in between
            let pieceExists = rookPositions[rooksPosition].intermediate_squares.find(square => {
                if (typeof boardPositions[square] === 'object') {
                    return true;
                }
            });
            if (pieceExists) {
                return false;
            }

            // The king should not pass through the square under attack
            let passingSquare = rookPositions[rooksPosition].passing_square;
            let updatedPositions = {...boardPositions};
            updatedPositions[passingSquare] = updatedPositions[kingsPosition];
            delete updatedPositions[kingsPosition];

            if (Game.checkIfKingIsInCheck(piece.color, updatedPositions)) {
                return false;
            }

            // Allow only if the King will not end up in Check
            let castlingMove = {
                piece: piece,
                from: position,
                to: possibleCastlingSquare,
                castling: true
            }
            if (Game.willThisMoveLeaveTheKingInCheck(castlingMove, {...boardPositions})) {
                return false;
            }

            return true;
        });

        setCastlingSquares(possibleCastlingSquares);
    }

    return [castlingSquares, updateCastlingSquares, updateCastlingEligibility];
}

export { useCastling };