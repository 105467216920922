import { useEffect, useLayoutEffect, useContext } from 'react';
import { ThemeContext } from './ThemeContext.js';

function SingleMoveNotation (props) {
    return (
        <div className="row single-move py-1 fw-bold">
            <div className="col-2">
                {props.moveIndex}.
            </div>
            <div className="col-5">
                { props.moveNotations[0] ? props.moveNotations[0].notation : '' }
            </div>
            <div className="col-5">
                { props.moveNotations[1] ? props.moveNotations[1].notation : '' }
            </div>
        </div>
    );
}

function GameMoves (props) {
    const theme = useContext(ThemeContext);

    useEffect(() => {
        let cardBody = document.getElementsByClassName('move-notations')[0].getElementsByClassName('card-body')[0];
        cardBody.scrollTop = cardBody.scrollHeight;
    }, [props.moveNotations]);

    useLayoutEffect(() => {
        setTimeout(() => {
            let reduceHeight = document.getElementsByClassName('captured-pieces')[0].offsetHeight + 230;
            document.getElementsByClassName('move-notations')[0].style.maxHeight = 'calc(100vh - '+ reduceHeight +'px)';
        }, 200);
    }, [props.setHeight]);

    const moveNotationsHtml = [];
    props.moveNotations.forEach((moveNotations, index) => {
        moveNotationsHtml.push(
            <SingleMoveNotation key={index+1} moveNotations={moveNotations} moveIndex={index+1} />
        );
    });

    return (
        <>
            <div className={"card move-notations card-" + theme}>
                <div className="card-header">
                    <h3 className="text-center m-0">Moves</h3>
                </div>

                <div className="card-body">
                    <div className="row game-info-moves">
                        {
                            props.moveNotations[0].length == 0 &&
                            <div className="col-12 text-center">
                                <h5 className="text-muted">No moves yet. Start the game!</h5>
                            </div>
                        }

                        {
                            props.moveNotations[0].length > 0 &&
                            <div className="col-12">
                                { moveNotationsHtml }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default GameMoves;