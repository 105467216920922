import {useState, useEffect, useContext} from 'react';
import {Modal} from 'bootstrap';
import { ThemeContext } from './ThemeContext.js';

function ConfrimNewGame(props) {
    const [modalInstance, setModalInstance] = useState(false);
    const theme = useContext(ThemeContext);

    useEffect(() => {
        let confirmNewGameModal = new Modal(document.getElementById('confirm-new-game-modal'), {});
        confirmNewGameModal.show();
        setModalInstance(confirmNewGameModal);
    }, []);

    function startNewGame(startNewGame) {
        modalInstance.hide();

        let modalBackDrops = document.getElementsByClassName('modal-backdrop');
        while(modalBackDrops.length) {
            modalBackDrops[0].remove();
        }

        props.startNewGame(startNewGame);
    }

    return (
        <div className={"modal confirm-new-game-modal modal-" + theme} tabIndex="-1" id="confirm-new-game-modal" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-header justify-content-center">
                        <h5 className="modal-title fw-bold">Are you sure, you want to start new game?</h5>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className={"btn btn-primary btn-" + theme} onClick={() => startNewGame(true)}>Yes</button>
                        <button type="button" className="btn btn-danger" onClick={() => startNewGame(false)} data-bs-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfrimNewGame;