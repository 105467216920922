export default function DemoInfo () {
    return (
        <>
            <div className="card mb-2 demo-info">
                <div className="card-header">
                    <h3 className="text-center m-0">Built with ReactJS</h3>
                </div>

                <div className="card-body p-2">
                    <div className="row">
                        <div className="col-12 text-center">
                            This chess game is built with ReactJS as part of the ReactJS Tutorial.&nbsp;
                            <a className="text-white" href="https://codingwithchess.com" target="_blank">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}