import { createRoot } from 'react-dom/client';
import PromotedPieceSelector from '../PromotedPieceSelector.js';

function promptForPromotedPieceSelection(args) {
    // Call and render the PromotedPieceSelector modal
    let targetDiv;
    if (! (targetDiv = document.getElementById('promote-piece-modal-root'))) {
        targetDiv = document.createElement('div');
        targetDiv.setAttribute('id', 'promote-piece-modal-root');
        document.body.appendChild(targetDiv);
    }

    let modalRoot = createRoot(targetDiv);
    modalRoot.render(
        <PromotedPieceSelector
            resolve={args.resolve}
            promotedPawnColor={args.promotedPawnColor}
            modalRoot={modalRoot}
            />
    );
}

function checkForPawnPromotion(move) {
    let isPawnPromoted = false;
    if (move.piece.type === 'pawn' && (move.to[1] == 8 || move.to[1] == 1)) {
        isPawnPromoted = true;
    }

    return new Promise((resolve, reject) => {
        resolve(isPawnPromoted);
    });
}

export { checkForPawnPromotion, promptForPromotedPieceSelection };