import { useState } from 'react';
import * as Game from './Game.js';

function useRecordMoves() {
    const [moveNotations, setMoveNotations] = useState([[]]);
    const pieceSymbol = {
        'king': 'K',
        'queen': 'Q',
        'rook': 'R',
        'bishop': 'B',
        'knight': 'N',
        'pawn': ''
    };

    const getMoveNotation = function (move, boardPositions) {
        let notation = pieceSymbol[move.piece.type];
        let shortNotation = notation;

        if (move.castling) {
            notation = (move.from[0] == 'a' || move.to[0] == 'a') ? '0-0-0' : '0-0';
            shortNotation = notation;

            return {notation: shortNotation, long_notation: notation};
        }

        notation += move.from;
        notation += move.capturing ? 'x' : '-';
        notation += move.to;

        shortNotation += move.capturing ? 'x' : '';
        shortNotation += move.to;
        if (move.piece.type == 'pawn' && move.capturing) {
            shortNotation = move.from[0] + shortNotation;
        }

        if (move.enPassant) {
            notation += ' e.p.';
            shortNotation += ' e.p.';
        }

        if (move.promotedPiece) {
            notation += pieceSymbol[move.promotedPiece.type];
            shortNotation += pieceSymbol[move.promotedPiece.type];
        }

        if (move.checkMate) {
            // Checkmate is notated with a "#" at the end of the move.
            notation += '#';
            shortNotation += '#';
        } else if (move.check) {
            // When a king is in check, "+" is added at the end of the move.
            notation += '+';
            shortNotation += '+';
        }

        shortNotation = disambiguateMoveNotation(move, shortNotation, boardPositions);
        return {notation: shortNotation, long_notation: notation};
    }

    const disambiguateMoveNotation = function (move, shortNotation, boardPositions) {
        // If Pawn or King piece, then there is no ambiguity
        if (['king', 'pawn'].includes(move.piece.type)) {
            return shortNotation;
        }

        let ambiguousPositions = [];
        Object.keys(boardPositions).forEach(position => {
            if (position == move.from) {
                return false;
            }

            // Find the other similar chess pieces
            let chessPiece = boardPositions[position];
            if (chessPiece && chessPiece.type == move.piece.type && chessPiece.color == move.piece.color) {
                // For all other similar pieces, find the movable squares
                let newMovableSquares, newCapturableSquares;
                [newMovableSquares, newCapturableSquares] = Game.getMovableSquares(
                                                                chessPiece,
                                                                position,
                                                                {...boardPositions}
                                                            );

                // If the movable squares includes the current move to square,
                // then add the piece position to ambiguous positions list
                if (newMovableSquares.includes(move.to)) {
                    ambiguousPositions.push(position);
                }
            }
        });

        let onSameFile = false;
        let onSameRank = false;
        // If there are ambiguous positions
        ambiguousPositions.forEach(ambiguousPosition => {
            // Check if the two pieces are on the same file/column
            if (ambiguousPosition[0] === move.from[0]) {
                onSameFile = true;
            }

            // Check if the two pieces are on the same rank/row
            if (ambiguousPosition[1] === move.from[1]) {
                onSameRank = true;
            }
        });

        let additionalNotation = '';
        if (ambiguousPositions.length && ! onSameFile) {
            // For similar chess pieces not on same file or rank
            // For pieces moving diagonally
            additionalNotation = move.from[0];
        }
        if (onSameRank) {
            additionalNotation = move.from[0];
        }
        if (onSameFile) {
            additionalNotation += move.from[1];
        }

        shortNotation = shortNotation[0] + additionalNotation + shortNotation.slice(1);
        return shortNotation;
    }

    const recordMove = function (move, boardPositions) {
        let moveNotation = getMoveNotation(move, boardPositions);
        let currentMoveNotations = [...moveNotations];

        // let lastMoves = moveNotations.at(-1);
        let lastMoves = moveNotations[moveNotations.length - 1];
        let lastMoveIndex = moveNotations.length - 1;
        if (lastMoves.length == 2) {
            lastMoves = [];
            lastMoveIndex++;
        }

        lastMoves.push(moveNotation);
        currentMoveNotations[lastMoveIndex] = lastMoves;
        setMoveNotations(currentMoveNotations);
    }

    return [moveNotations, recordMove];
}

export { useRecordMoves };