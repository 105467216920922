const chessSquareNumbers = {
    'a1': 1, 'b1': 2, 'c1': 3, 'd1': 4, 'e1': 5, 'f1': 6, 'g1': 7, 'h1': 8,
    'a2': 9, 'b2': 10, 'c2': 11, 'd2': 12, 'e2': 13, 'f2': 14, 'g2': 15, 'h2': 16,
    'a3': 17, 'b3': 18, 'c3': 19, 'd3': 20, 'e3': 21, 'f3': 22, 'g3': 23, 'h3': 24,
    'a4': 25, 'b4': 26, 'c4': 27, 'd4': 28, 'e4': 29, 'f4': 30, 'g4': 31, 'h4': 32,
    'a5': 33, 'b5': 34, 'c5': 35, 'd5': 36, 'e5': 37, 'f5': 38, 'g5': 39, 'h5': 40,
    'a6': 41, 'b6': 42, 'c6': 43, 'd6': 44, 'e6': 45, 'f6': 46, 'g6': 47, 'h6': 48,
    'a7': 49, 'b7': 50, 'c7': 51, 'd7': 52, 'e7': 53, 'f7': 54, 'g7': 55, 'h7': 56,
    'a8': 57, 'b8': 58, 'c8': 59, 'd8': 60, 'e8': 61, 'f8': 62, 'g8': 63, 'h8': 64
};

function getSquareNotationFromNumber(number) {
    return Object.keys(chessSquareNumbers).find(key => chessSquareNumbers[key] === number);
}

function nextForwardSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    currentSquare += 8;

    if (currentSquare > 64) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function nextBackwardSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    currentSquare -= 8;

    if (currentSquare < 1) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function nextHorizontalRightSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];

    if (currentSquare % 8 == 0) {
        return false;
    }

    currentSquare++;

    return getSquareNotationFromNumber(currentSquare);
}

function nextHorizontalLeftSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    currentSquare--;

    if (currentSquare % 8 == 0) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function nextDiagonalForwardRightSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    if (currentSquare % 8 == 0) {
        return false;
    }

    currentSquare += 9;
    if (currentSquare > 64) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function nextDiagonalForwardLeftSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    currentSquare += 7;

    if (currentSquare > 64 || currentSquare % 8 == 0) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function nextDiagonalBackwardRightSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    if (currentSquare % 8 == 0) {
        return false;
    }

    currentSquare -= 7;
    if (currentSquare < 1) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function nextDiagonalBackwardLeftSquare(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    currentSquare -= 9;

    if (currentSquare < 1 || currentSquare % 8 == 0) {
        return false;
    }

    return getSquareNotationFromNumber(currentSquare);
}

function getNextSquare(currentPosition, direction) {
    switch(direction) {
        case 'forward':
            return nextForwardSquare(currentPosition);
        case 'backward':
            return nextBackwardSquare(currentPosition);
        case 'horizontal-right':
            return nextHorizontalRightSquare(currentPosition);
        case 'horizontal-left':
            return nextHorizontalLeftSquare(currentPosition);
        case 'forward-right':
            return nextDiagonalForwardRightSquare(currentPosition);
        case 'forward-left':
            return nextDiagonalForwardLeftSquare(currentPosition);
        case 'backward-right':
            return nextDiagonalBackwardRightSquare(currentPosition);
        case 'backward-left':
            return nextDiagonalBackwardLeftSquare(currentPosition);
    }
}

function getCellsInBoundary(rowStart, ...cells) {
    if (rowStart > 57 || rowStart < 1) {
        return false;
    }

    return cells.filter(cell => cell >= rowStart && cell < (rowStart + 8));
}

function getKnightMovableSquares(currentPosition) {
    let currentSquare = chessSquareNumbers[currentPosition];
    let movableSquareNumbers = [];
    let possibleMoves = [];
    let currentRowStart = (currentSquare % 8 == 0) ? currentSquare - 7 : currentSquare - ((currentSquare % 8) - 1);
    let inBoundCells = [];

    inBoundCells = getCellsInBoundary(currentRowStart + 8, currentSquare + 6, currentSquare + 10);
    if (inBoundCells) {
        movableSquareNumbers.push(...inBoundCells);
    }

    inBoundCells = getCellsInBoundary(currentRowStart + 16, currentSquare + 15, currentSquare + 17);
    if (inBoundCells) {
        movableSquareNumbers.push(...inBoundCells);
    }

    inBoundCells = getCellsInBoundary(currentRowStart - 8, currentSquare - 6, currentSquare - 10);
    if (inBoundCells) {
        movableSquareNumbers.push(...inBoundCells);
    }

    inBoundCells = getCellsInBoundary(currentRowStart - 16, currentSquare - 15, currentSquare - 17);
    if (inBoundCells) {
        movableSquareNumbers.push(...inBoundCells);
    }

    movableSquareNumbers.forEach(squareNumber => {
        possibleMoves.push(getSquareNotationFromNumber(squareNumber));
    })

    return possibleMoves;
}

export {getNextSquare, getKnightMovableSquares};