import {useState, useEffect, useContext} from 'react';
import {Modal} from 'bootstrap';
import { ThemeContext } from './ThemeContext.js';

function GameDrawn(props) {
    const [modalInstance, setModalInstance] = useState(false);
    const theme = useContext(ThemeContext);

    useEffect(() => {
        let gameDrawnModal = new Modal(document.getElementById('game-drawn-modal'), {});
        gameDrawnModal.show();
        setModalInstance(gameDrawnModal);
    }, []);

    function closeModal() {
        modalInstance.hide();

        let modalBackDrops = document.getElementsByClassName('modal-backdrop');
        while(modalBackDrops.length) {
            modalBackDrops[0].remove();
        }
    }

    return (
        <div className={"modal game-drawn-modal modal-" + theme}tabIndex="-1" id="game-drawn-modal" data-bs-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-header justify-content-center">
                        <h5 className="modal-title fw-bold">Game Draw!</h5>
                    </div>
                    <div className="modal-body">
                        <h4>The Game has been drawn because of { props.draw }</h4>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className={"btn btn-primary btn-" + theme} onClick={closeModal}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameDrawn;