import {useState, useEffect} from 'react';
import {Modal} from 'bootstrap';

function PromotedPiece(props) {
    return (
        <div className={"d-inline-block promote-piece " + (props.selected ? 'selected' : '')}
            onClick={() => props.setSelectedPiece(props.chessPiece)}>
            <img src={props.image} /> <br />
            <input type="checkbox" className="pointer" checked={props.selected} readOnly />
        </div>
    );
}

function PromotedPieceSelector(props) {
    const chessPieces = {
        white: {
            queen: 'images/queen-white.png',
            knight: 'images/knight-white.png',
            rook: 'images/rook-white.png',
            bishop: 'images/bishop-white.png'
        },
        black: {
            queen: 'images/queen-black.png',
            knight: 'images/knight-black.png',
            rook: 'images/rook-black.png',
            bishop: 'images/bishop-black.png'
        }
    };
    const [selectedPiece, setSelectedPiece] = useState(false);
    const [modalInstance, setModalInstance] = useState(false);

    useEffect(() => {
        let promotedPieceModal = new Modal(document.getElementById('promote-piece-modal'), {});
        promotedPieceModal.show();
        setModalInstance(promotedPieceModal);
    }, []);

    function selectPiece() {
        if (selectedPiece) {
            let piece = {
                type: selectedPiece,
                color: props.promotedPawnColor
            }

            modalInstance.hide();

            let modalBackDrops = document.getElementsByClassName('modal-backdrop');
            while(modalBackDrops.length) {
                modalBackDrops[0].remove();
            }

            props.resolve(piece);
            props.modalRoot.unmount();
        }
    }

    const chessPiecesHtml = [];
    Object.keys(chessPieces[props.promotedPawnColor]).forEach(chessPiece => {
        chessPiecesHtml.push(
            <PromotedPiece
                key={chessPiece}
                chessPiece={chessPiece}
                image={chessPieces[props.promotedPawnColor][chessPiece]}
                setSelectedPiece={setSelectedPiece}
                selected={ selectedPiece === chessPiece }
                />
        );
    });

    return (
        <div className={"modal promote-piece-modal modal-" + props.theme} tabIndex="-1" id="promote-piece-modal" data-bs-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-header justify-content-center">
                        <h5 className="modal-title fw-bold">Awesome! Pawn is Promoted!</h5>
                    </div>
                    <div className="modal-body">
                        <p className="my-1 fw-bold">Please select a Piece of your choice to replace the Promoted Pawn.</p>

                        {chessPiecesHtml}
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className={"btn btn-primary btn-" + props.theme} onClick={selectPiece}>Select</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromotedPieceSelector;